@import '../../styles/transitions.scss';

.page-transition {
  position: fixed;
  inset: 0;
  background-color: var(--color-pink-light);
  transform: translateX(100%);
  @include transition(transform);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  &.enter {
    transform: translateX(0);
  }

  &.exit {
    transform: translateX(-100%);
  }
}
