@mixin dark-theme {
  background-color: #000;
  body {
    color: #fff;
    background-color: #000;
    --color-black: #3e3e3e;
    --color-pink: #929292;
    --color-pink-dark: #6b6b6b;
    --color-sky-blue: #4a4a4a;
    --color-pink-light: #b5b5b5;

    form {
      color: var(--color-black);
    }

    .accent {
      background-color: var(--color-white);
      text-shadow: 0 0 5px var(--color-white);
    }

    ::selection {
      background-color: var(--color-white);
      color: var(--color-black);
    }

    a {
      color: var(--color-blue);
    }

    button.primary,
    a[role='button'].primary,
    *[role='button'].primary,
    input[type='submit'].primary {
      background-color: var(--color-pink);
      color: var(--color-white);

      &:hover,
      &:focus {
        background-color: var(--color-dark-blue);
      }
    }

    button.ternary,
    a[role='button'].ternary,
    *[role='button'].ternary,
    input[type='submit'].ternary {
      background-color: var(--color-brown);
      color: var(--color-white);

      &:hover,
      &:focus {
        background-color: var(--color-blue);
      }
    }

    button.dark {
      background-color: var(--color-black);
      color: var(--color-white);

      &:hover,
      &:focus {
        background-color: var(--color-dark-blue);
      }
    }

    .tag {
      background-color: var(--color-brown);
    }

    footer {
      color: var(--color-white);
    }

    .browser {
      background-color: var(--color-black);
      color: var(--color-white);

      .fake-content span {
        background-color: var(--color-pink);
      }
    }

    .bg-dark-blue {
      background-color: var(--color-brown);
    }

    .bg-blue {
      background-color: var(--color-brown);
      color: white;
    }

    .secondary {
      background-color: var(--color-brown);
      color: white;

      &:hover {
        background-color: var(--color-dark-blue);
      }
    }

    .text-black {
      color: var(--color-white);
    }
  }
}

.dark-theme {
  @include dark-theme;
}
