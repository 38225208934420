@import './../../styles/transitions.scss';
@import './../../styles/mq.scss';
.image-container {
  @include transition(transform);
  position: relative;
  z-index: 2;
  margin-top: 10px;
  &::after {
    content: '';
    position: absolute;
    top: -20px;
    left: 20px;
    width: 100%;
    height: 100%;
    background-color: var(--color-pink-light);
    opacity: 0.8;
    transform: rotate(0deg);
    border-radius: var(--border-radius);
    @include transition(transform);
    z-index: 1;
  }

  &:hover {
    transform: rotate(10deg);
    &::after {
      transform: rotate(10deg);
    }
  }

  img {
    border-radius: var(--border-radius);
    z-index: 2;
    position: relative;
    max-width: 400px;
  }
}

img {
  user-select: none;
}

.isBackgroundImage {
  height: 30vh;
  border-radius: var(--border-radius);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-width: 48%;
  @include breakpoint('md') {
    height: 50vh;
  }
}
