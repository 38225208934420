.post-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

.post-page {
  margin-top: 100px;

  li {
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
}

#quotable a {
  font-size: 1rem;
}

.post-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag {
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  font-size: 0.8rem;
}

p pre {
  font-size: 1rem;
}

.code-block {
  pre,
  code {
    display: block !important;
    font-size: 1rem;
  }
}
