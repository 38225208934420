.browser {
  background-color: var(--color-white);
  min-width: 90%;
  border-radius: var(--border-radius);
  position: relative;
  border: 6px solid var(--color-sky-blue);

  .content {
    margin-left: 20px;
  }

  a {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .bar {
    background-color: var(--color-sky-blue);
    position: relative;
    padding: calc(var(--card-spacing) / 2) 0;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 40px;
      transform: translateX(-50%) translateY(-50%);
      width: 50px;
      height: 8px;
      background-color: var(--color-white);
      border-radius: var(--border-radius);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 100px;
      transform: translateX(-50%) translateY(-50%);
      width: 50px;
      height: 8px;
      background-color: var(--color-white);
      border-radius: var(--border-radius);
    }
  }

  .fake-content {
    padding: calc(var(--card-spacing) / 2);
    span {
      background-color: var(--color-sky-blue);
      width: 90%;
      height: 20px;
      display: block;
      margin-bottom: 10px;
      border-radius: var(--border-radius);

      &.image {
        width: 100px;
        height: 100px;
      }
      &:first-child {
        margin-bottom: 10px;
        width: 70%;
      }

      &:last-child {
        width: 60%;
      }
    }
  }
}
