@import './../../styles/mq.scss';
@import './../../styles/transitions.scss';

.navigation {
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  z-index: 8;
  background-color: var(--color-pink-light);
  margin: 0 auto;
  transition:
    width 0.3s ease-in-out,
    top 0.3s ease-in-out,
    border-radius 0.3s ease-in-out;
  width: 100%;
  border-radius: 0;
  overflow: hidden;

  &.scrolled-to-top {
    @include breakpoint(xl) {
      width: 1200px;
      top: 20px;
      border-radius: var(--border-radius);
    }
  }

  .nav-link {
    padding: 20px;
    border-radius: 0;
    background-color: var(--color-pink-light);
    color: var(--color-black);
    font-size: 1.6rem;
    font-weight: 700;
    &:hover {
      background-color: var(--color-pink);
      color: var(--color-white);
    }

    &.active {
      background-color: var(--color-pink);
      color: var(--color-white);
    }
  }
}
